import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box } from "../components/Core";
import { HelmetTitle } from "../components/HelmetTitle";

const WhyAllo = () => {
  return (
    <>
      <HelmetTitle title="Allo — Why Are We Building Allo?" />
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="9">
                <Box mt={["40px", null, "50px"]}>
                  <Title>Why Are We Building Allo?</Title>
                </Box>
                <Box mt={["20px", null, "60px"]}>
                  <Text>
                    It’s more challenging than ever to be in control of our
                    finances. Millions of products are available at the click of
                    a button, “buy now, pay later” is commonplace, subscriptions
                    are renewed automatically, companies target us with
                    personalized advertising, the list goes on and on. It’s no
                    wonder why money is the number one stressor for most of us.
                    <br />
                    <br />
                    We believe that a <b>mindful money practice</b> is the
                    answer. We can pay more attention — not just to the numbers,
                    but to the values, the dreams, the priorities that drive our
                    day-to-day financial life. We can be more in touch with how
                    we feel about them — what sits well, and what doesn't. By{" "}
                    <b>building awareness</b>, we can cut through the stress and
                    feel more <b>peaceful</b> and <b>grateful</b>.
                    <br />
                    <br />
                    Better yet, when we are more aware, we make better
                    decisions. Research shows that over time, a sustained
                    awareness practice will lead to <b>
                      long-lasting changes
                    </b>{" "}
                    to spend less on those things you consider wasteful, spend
                    joyfully on the things you value today, and leave enough
                    saved for the things you value in your future.
                    <br />
                    <br />
                    <br />
                    ❤️ Team Allo
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default WhyAllo;
